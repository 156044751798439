import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  renunciasReceita: [],
  renunciasReceitaDetalhe: []
});

function renunciaReceitaReducer(state = initialState, action) {
  switch (action.type) {
    case (types.RENUNCIA_RECEITA_START, types.RENUNCIA_RECEITA_DETALHE_START): {
      return startLoad(state);
    }

    case (types.RENUNCIA_RECEITA_ERROR, types.RENUNCIA_RECEITA_DETALHE_ERROR): {
      return endLoad(state);
    }

    case types.RENUNCIA_RECEITA_SUCCESS: {
      return endLoad(state.set('renunciasReceita', fromJS(action.payload)));
    }

    case types.RENUNCIA_RECEITA_DETALHE_SUCCESS: {
      return endLoad(
        state.set('renunciasReceitaDetalhe', fromJS(action.payload))
      );
    }
  }

  return state;
}

export default renunciaReceitaReducer;
