import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class DiarioFiltro extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onClearFilter: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    mes: PropTypes.number,
    findDiarios: PropTypes.func.isRequired,
    onChangeFilter: PropTypes.func.isRequired
  };

  meses = [
    { nome: 'Janeiro', value: 1 },
    { nome: 'Fevereiro', value: 2 },
    { nome: 'Março', value: 3 },
    { nome: 'Abril', value: 4 },
    { nome: 'Maio', value: 5 },
    { nome: 'Junho', value: 6 },
    { nome: 'Julho', value: 7 },
    { nome: 'Agosto', value: 8 },
    { nome: 'Setembro', value: 9 },
    { nome: 'Outubro', value: 10 },
    { nome: 'Novembro', value: 11 },
    { nome: 'Dezembro', value: 12 }
  ];

  ano = new Date().getFullYear();

  anos = [
    this.ano,
    this.ano - 1,
    this.ano - 2,
    this.ano - 3,
    this.ano - 4,
    this.ano - 5,
    this.ano - 6,
    this.ano - 7,
    this.ano - 8,
    this.ano - 9,
    this.ano - 11,
    this.ano - 12
  ];

  renderMeses = mesPublicacao => (
    <option key={mesPublicacao.value} value={mesPublicacao.value}>
      {mesPublicacao.nome}
    </option>
  );
  renderAnos = anoPublicacao => (
    <option key={anoPublicacao} value={anoPublicacao}>
      {anoPublicacao}
    </option>
  );

  onConsultarDiarios = () => {
    const { findDiarios, filter } = this.props;

    findDiarios(filter);
  };

  onChange = event => {
    const { onChangeFilter } = this.props;

    const name = event.target.name;
    const value = event.target.value;

    onChangeFilter(name, value);
  };

  render() {
    const { show, onClearFilter } = this.props;
    const {
      mesPublicacao,
      chave,
      anoPublicacao,
      numero,
      dataInicio,
      dataFim
    } = this.props.filter;

    if (!show) {
      return null;
    }

    return (
      <React.Fragment>
        <Panel title="Consultar em Diários" mobileClose isForm>
          <Row>
            <Col xs={3} sm={3} md={3}>
              <Input
                name="dataInicio"
                label="Data Início"
                type="date"
                onChange={this.onChange}
                value={dataInicio}
              />
            </Col>
            <Col xs={3} sm={3} md={3}>
              <Input
                name="dataFim"
                label="Data Fim"
                type="date"
                onChange={this.onChange}
                value={dataFim}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="mesPublicacao"
                label="Mês"
                type="select"
                value={mesPublicacao}
                onChange={this.onChange}
              >
                <option value="">Selecione</option>
                {this.meses.map(this.renderMeses)}
              </Input>
            </Col>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="anoPublicacao"
                label="Ano"
                type="select"
                value={anoPublicacao}
                onChange={this.onChange}
              >
                <option value="">Selecione</option>
                {this.anos.map(this.renderAnos)}
              </Input>
            </Col>
            <Col xs={2} sm={2} md={2}>
              <Input
                name="numero"
                label="Número"
                type="text"
                onChange={this.onChange}
                value={numero}
              />
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Input
                name="chave"
                label="Palavra chave"
                type="text"
                value={chave}
                onChange={this.onChange}
              />
            </Col>
            <Col xs={12} sm={4} md={4}>
              <FilterButtons
                idBtnReset="btnResetFilter"
                onConsulta={this.onConsultarDiarios}
                onReset={onClearFilter}
              />
            </Col>
          </Row>
        </Panel>
      </React.Fragment>
    );
  }
}
