import { Route, Switch } from 'react-router-dom';

import Acessibilidade from './acessibilidade/Form.react.js';
import Adiantamento from './adiantamento/Form.react.js';
import AdiantamentoDetalhes from './adiantamento/FormDetail.react.js';
import AditivoDetalhe from './subvencoesAditivo/FormDetail.react.js';
import AditivosDetalhes from './aditivos/FormDetail.react';
import AdjudicacaoDetalhes from './adjudicacoes/FormDetail.react';
import Afastamento from './afastamento/Form.react.js';
import AgendaPrevisaoPagamentos from './agendaprevisaopagamento/Form.react.js';
import AjudaCusto from './ajudacusto/Form.react.js';
import AjudaCustoDetalhes from './ajudacusto/FormDetail.react.js';
import AlmoxarifadoEstoque from './almoxarifado/FormEstoque.react.js';
import AlmoxarifadoMovimentacao from './almoxarifado/FormMovimentacao.react.js';
import AlvaraDetalhe from './alvara/FormDetalhe.react.js';
import Alvaras from './alvara/Form.react.js';
import AptoPagamento from './aptospagamentos/Form.react.js';
import Arrecadacao from './arrecadacao/Form.react.js';
import ArrecadacaoPorBairros from './arrecadacao/DetalhePorBairros.react.js';
import AudienciasPublicas from './audienciasPublicas/Form.react.js';
import AudienciasPublicasDetalhes from './audienciasPublicas/FormDetail.react.js';
import Autonomos from './servidores/FormAutonomoDetail.react.js';
import AutonomosOxy from './servidoresOxy/FormAutonomoDetail.react.js';
import AutorizacaoDetalhes from './autorizacoes/FormDetail.react.js';
import AvaliacaoPPA from './avaliacaoppa/Form.react.js';
import CargoMatrizOxy from './cargos/oxy/CargoMatrizOxy.react';
import Cargos from './cargos/Form.react.js';
import CargosDetalhes from './cargos/FormDetail.react.js';
import CartaServicos from './cartaServicos/Form.react.js';
import Cedidos from './cedidos/Form.react.js';
import ComprasDiretasFornecedor from './comprasdiretasfornecedor/Form.react.js';
import ComprasDiretasItens from './comprasdiretasitens/Form.react.js';
import ConsultaSic from './consultasic/Form.react.js';
import ContabRenunciaReceitas from './contab-renuncia-receitas/Form.react.js';
import ContabRenunciaReceitasDetalhe from './contab-renuncia-receitas/FormDetail.react.js';
import Contato from './contato/Form.react.js';
import Contratos from './contratos/Form.react.js';
import ContratosCovid from './contratos/FormCovid.react.js';
import ContratosDetalhes from './contratos/FormDetail.react.js';
import Convenios from './convenios/Form.react.js';
import ConveniosDetalhes from './convenios/FormDetail.react.js';
import CotasParlamentares from './cotas-parlamentares/Form.react.js';
import CreditosAdicionais from './creditosadicionais/Form.react.js';
import DadosAbertos from './dadosAbertos/Form.react.js';
import Dashboard from './dashboard/Form.react.js';
import Despesa from './despesa/Form.react.js';
import DespesaDetalhada from './despesaDetalhada/Form.react.js';
import DetalheReceitasPorFonte from './receita/FormPorFonteDetail.react.js';
import Diarias from './diarias/Form.react.js';
import DiariasDetalhes from './diarias/FormDetail.react.js';
import DividaAtiva from './dividaativa/Form.react.js';
import ESIC from './esic/Form.react.js';
import EmLicitacao from './emliquidacao/Form.react.js';
import EmManutencao from './manutencao/Form.react.js';
import EmendaParlamentar from './emendaParlamentar/Form.react.js';
import EmendaParlamentarDetalhe from './emendaParlamentar/FormDetail.react.js';
import EmpenhoDetalhe from './empenhos/FormDetalhe.react.js';
import EmpenhoItemDetalhe from './empenhos/FormDetalheItemPerc.react.js';
import Empenhos from './empenhos/Form.react.js';
import EntidadePrincipal from './entidades/Form.react.js';
import Estagiarios from './servidores/FormEstagiarioDetail.react.js';
import EstagiariosOxy from './servidoresOxy/FormEstagiarioDetail.react.js';
import FormConselhoDetalhe from './conselhos/FormConselhoDetalhe.react';
import FormConselhos from './conselhos/FormConselhos.react.js';
import FormConsumo from './almoxarifado/FormConsumo.react.js';
import FormContatoCovid from './contato-covid/Form.react.js';
import FormDiario from './diario/FormDiario.react';
import FormDocumentos from './documentosSaude/FormDocumentos.react';
import FormEscalas from './escalasProfissionais/FormEscalas.react.js';
import FormHorasExtras from './horasExtras/Form.react.js';
import FormQuadroPessoal from './quadro-pessoal/Form.react';
import FormSelo from './selo/Form.react.js';
import FormVerbas from './verbas/Form.react.js';
import FornecedoresAPagar from './fornecedores/Form.react.js';
import Glossario from './glossario/Form.react.js';
import Home from './home/Form.react.js';
import HomologacaoDetalhes from './homologacoes/FormDetail.react';
import HorasExtrasDetalhes from './horasExtras/HorasExtrasDetalhes.react.js';
import HotKeys from './hotkeys/Form.react.js';
import Inativos from './servidoresOxy/FormInativoDetail.react.js';
import LeisEAtos from './leiseatos/Form.react.js';
import LeisEAtosDetalhes from './leiseatos/FormDetail.react.js';
import LeisLGPD from './leislgpd/Form.react.js';
import LeisRegemPortal from './leisregemportal/Form.react.js';
import LicitacaoDetalhe from './licitacoes/FormDetail.react.js';
import LicitacaoParticipantesDetalhe from './participantes/FormDetalhe.react.js';
import Licitacoes from './licitacoes/Form.react.js';
import LicitacoesCovid from './licitacoes/FormCovid.react.js';
import LicitacoesParticipantes from './participantes/Form.react.js';
import LicitacoesAtaDeAdesao from './licitacoes/FormAtaAdesao.react.js';
import LinksUteis from './linkutil/Form.react.js';
import Liquidacoes from './liquidacoes/Form.react.js';
import MapaSite from './mapasite/Form.react.js';
import MedidasAdotadasCobranca from './medidasadotadas/Form.react.js';
import Menu from './menu/Form.react.js';
import MovimentoGaragem from './movimentogaragem/Form.react.js';
import MovimentoGaragemDetalhe from './movimentogaragem/FormDetail.react.js';
import NotFound from 'portaltransparencia-common/notfound/Page.react.js';
import NotaExtraorcamentaria from './notaextraorcamentaria/Form.react.js';
import NotaExtraorcamentariaDetalhe from './notaextraorcamentaria/FormDetalhe.react.js';
import ObrasPublicas from './obraspublicas/Form.react.js';
import ObrasPublicasDetalhes from './obraspublicas/FormDetail.react.js';
import Orcamento from './orcamento/Form.react.js';
import OrdemCronologicaPagamento from './ordemcronologicapagamentos/Form.react.js';
import Organograma from './organograma/Form.react.js';
import Patrimonio from './patrimonio/Form.react.js';
import PatrimonioDetalhes from './patrimonio/FormDetail.react.js';
import Pensionistas from './servidoresOxy/FormPensionistaDetail.react.js';
import Perguntas from './perguntas/Form.react.js';
import Permutas from './permutas/Form.react.js';
import Pesquisa from './pesquisa/Form.react.js';
import PrestacaoContasDetalhe from './subvencoesPrestacaoContas/FormDetail.react.js';
import ProcessoDisciplinar from './processoDisciplinar/Form.react.js';
import ProcessosSigilosos from './processosSigilosos/Form.react.js';
import Publicacoes from './publicacoes/FormPublicacao.react.js';
import React from 'react';
import Receita from './receita/Form.react.js';
import ReceitaCovid from './receita/FormCovid.react.js';
import ReceitaDetalhada from './receitaDetalhada/Form.react.js';
import ReceitaDia from './receita/FormDia.react.js';
import ReceitaEspecifica from './receita/FormReceitaEspecifica.react.js';
import ReceitaPorFonte from './receita/FormPorFonte.react.js';
import ReceitaRazao from './receita/FormDiaDetail.react.js';
import ReceitaXDespesa from './receitaXdespesa/Form.react.js';
import RecursoDetalhe from './recursos/FormDetail.react.js';
import RedirectAdminPage from './admin/adminPage.react';
import RenunciaReceitasPage from './renuncia-receitas/RenunciaReceitasPage.react.js';
import Repasses from './repasses/Form.react.js';
import RepassesDetalhes from './repasses/FormDetail.react.js';
import Responsavel from './responsavel/Form.react.js';
import RestosAPagar from './restosapagar/Form.react.js';
import ServidorDetalhe from './servidores/FormDetail.react.js';
import ServidorDetalheOxy from './servidoresOxy/FormDetail.react.js';
import Servidores from './servidores/Form.react.js';
import ServidoresOxy from './servidoresOxy/Form.react.js';
import SicFisico from './sicfisico/Form.react.js';
import SubvencaoDetalhe from './subvencoes/FormDetalhe.react.js';
import Subvencoes from './subvencoes/Form.react.js';
import SuprimentosFundos from './suprimentosfundos/Form.react.js';
import SuspensaoFornecedor from './suspensaoFornecedor/Form.react.js';
import Terceirizados from './terceirizados/Form.react.js';
import Veiculos from './veiculos/Form.react.js';
import VeiculosDetalhe from './veiculos/FormDetail.react.js';
import VerbasDetalhes from './verbas/VerbasDetalhes.react.js';
import eSicEstatistica from './esic/Estatisticas.react.js';
import eSicSolicitacao from './esic/FormSolicitacao.react.js';
import filaEsperaAtendimento from './filaEsperaAtendimento/Form.react.js';
import filaEsperaPaciente from './filaEsperaAtendimento/FormPacienteDetalhe.react.js';
import { getAccess } from 'portaltransparencia-common/libs/findMenuAtivo';
import Pca from './pca/Form.react.js';
import PcaDetalhe from './pca/FormDetalhe.react.js';
import AcordoSemRecurso from './acordoSemRecurso/Form.react.js';
import AcordoSemRecursoDetalhe from './acordoSemRecurso/FormDetail.react.js';
import FormEstruturaOrganizacional from './estrutura-organizacional/Form.react.js';
import FormEstruturaOrganizacionalDetail from './estrutura-organizacional/FormDetail.react.js';
import ContratosAtaDeAdesao from './contratos/FormAtaAdesao.react.js';

/* eslint-disable react/no-multi-comp */

export default function Routes(menuState) {
  const hasAccess = route => replace => {
    let hasValue = getAccess(menuState.menu.get('menu'), route);
    if (!hasValue) {
      replace({
        pathname: '/nao-encontrado'
      });
    }
    return hasValue;
  };

  const createProtectedRoute = (path, component) => {
    return (
      <Route
        path={path}
        component={component}
        onEnter={hasAccess(path)}
        exact
      />
    );
  };

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      {createProtectedRoute('/esic', ESIC)}
      <Route path="/sic-fisico" component={SicFisico} />
      <Route path="/esicSolicitacao" component={eSicSolicitacao} />
      <Route path="/esic/estatisticas" component={eSicEstatistica} />
      <Route path="/sic-consulta" component={ConsultaSic} />
      {createProtectedRoute('/adiantamento', Adiantamento)}
      <Route path="/adiantamento/detalhes" component={AdiantamentoDetalhes} />
      {createProtectedRoute('/ajuda-custo', AjudaCusto)}
      <Route path="/ajuda-custo/detalhes" component={AjudaCustoDetalhes} />
      {createProtectedRoute('/almoxarifado/posicao', AlmoxarifadoEstoque)}
      {createProtectedRoute('/almoxarifado/consumo', FormConsumo)}
      <Route
        path="/almoxarifado/:centrocusto/:item"
        component={AlmoxarifadoMovimentacao}
        exact
      />
      <Route path="/aptos-pagamentos" component={AptoPagamento} />
      {createProtectedRoute('/cargos', Cargos)}
      <Route path="/cargos/detalhes" component={CargosDetalhes} />
      <Route path="/cargos/matriz-vigente" component={CargoMatrizOxy} />
      {createProtectedRoute('/cedidos', Cedidos)}
      {createProtectedRoute('/permutas', Permutas)}
      {createProtectedRoute(
        '/compras-diretas-fornecedor',
        ComprasDiretasFornecedor
      )}
      {createProtectedRoute('/processos-disciplinares', ProcessoDisciplinar)}
      <Route
        path="/ordem-cronologica-pagamentos"
        component={OrdemCronologicaPagamento}
      />
      {createProtectedRoute('/compras-direta-itens', ComprasDiretasItens)}
      <Route path="/contato" component={Contato} />
      {createProtectedRoute('/contratos', Contratos)}
      <Route path="/contratos/detalhes" component={ContratosDetalhes} />
      {createProtectedRoute('/contratos-covid', ContratosCovid)}
      <Route
        path="/contratos-covid/detalhes"
        component={ContratosDetalhes}
        exact
      />
      {createProtectedRoute('/contratos-atadeadesao', ContratosAtaDeAdesao)}
      <Route
        path="/contratos-atadeadesao/detalhes"
        component={ContratosDetalhes}
        exact
      />
      {createProtectedRoute('/convenios', Convenios)}
      <Route path="/convenios/detalhes" component={ConveniosDetalhes} />
      <Route path="/dashboard" component={Dashboard} />
      {createProtectedRoute('/diarias', Diarias)}
      <Route path="/diarias/detalhes" component={DiariasDetalhes} />
      {createProtectedRoute('/dividaativa', DividaAtiva)}
      <Route path="/empenhos/detalhe" component={EmpenhoDetalhe} exact />
      <Route
        path="/empenhos/item/detalhe"
        component={EmpenhoItemDetalhe}
        exact
      />
      <Route path="/empenhos" component={Empenhos} exact />
      <Route path="/empenhos/:mostraFiltro" component={Empenhos} exact />
      <Route path="/empenhos-covid" component={Empenhos} exact />
      <Route path="/empenhos-fundeb" component={Empenhos} exact />
      <Route path="/empenhos-covid/:mostraFiltro" component={Empenhos} exact />
      <Route path="/empenhos-fundeb/:mostraFiltro" component={Empenhos} exact />
      <Route path="/receitaxdespesa-covid" component={ReceitaXDespesa} exact />
      <Route path="/receitaxdespesa-fundeb" component={ReceitaXDespesa} exact />
      <Route path="/receitas-fundeb" component={ReceitaEspecifica} exact />
      <Route path="/leislgpd" component={LeisLGPD} />
      {createProtectedRoute('/fornecedores', FornecedoresAPagar)}
      <Route
        path="/fornecedores/suspensos"
        component={SuspensaoFornecedor}
        exact
      />
      <Route path="/leiseatos-geral/detalhes" component={LeisEAtosDetalhes} />
      <Route path="/leiseatos-pessoal/detalhes" component={LeisEAtosDetalhes} />
      <Route path="/leiseatos-despesa/detalhes" component={LeisEAtosDetalhes} />
      <Route path="/leiseatos-receita/detalhes" component={LeisEAtosDetalhes} />
      <Route
        path="/leiseatos-geral/:tipoLeisEAtos/covid"
        component={LeisEAtos}
        exact
      />
      <Route path="/leiseatos-geral/:tipoLeisEAtos" component={LeisEAtos} />
      <Route path="/leiseatos-despesa/:tipoLeisEAtos" component={LeisEAtos} />
      <Route path="/leiseatos-pessoal/:tipoLeisEAtos" component={LeisEAtos} />
      <Route path="/leiseatos-receita/:tipoLeisEAtos" component={LeisEAtos} />
      <Route path="/liquidacoes/:tipo" component={Liquidacoes} />
      <Route path="/liquidacoes-covid/:tipo" component={Liquidacoes} />
      <Route path="/liquidacoes-fundeb/:tipo" component={Liquidacoes} />
      <Route path="/leisregemportal" component={LeisRegemPortal} />
      {createProtectedRoute('/licitacoes', Licitacoes)}
      {createProtectedRoute('/licitacoes-agendamentos', Licitacoes)}
      <Route
        path="/licitacoes-agendamentos/detalhes"
        component={LicitacaoDetalhe}
        exact
      />
      <Route path="/licitacoes/detalhes" component={LicitacaoDetalhe} exact />
      {createProtectedRoute('/licitacoes-covid', LicitacoesCovid)}
      <Route
        path="/licitacoes-covid/detalhes"
        component={LicitacaoDetalhe}
        exact
      />
      {createProtectedRoute('/licitacoes-atadeadesao', LicitacoesAtaDeAdesao)}
      <Route
        path="/licitacoes-atadeadesao/detalhes"
        component={LicitacaoDetalhe}
        exact
      />
      {createProtectedRoute('/links-uteis', LinksUteis)}
      {createProtectedRoute('/medidas-adotadas', MedidasAdotadasCobranca)}
      {createProtectedRoute('/movimentogaragem', MovimentoGaragem)}
      <Route
        path="/movimentogaragem/detalhes"
        component={MovimentoGaragemDetalhe}
      />
      {createProtectedRoute('/obraspublicas', ObrasPublicas)}
      <Route
        path="/obraspublicas/detalhes"
        component={ObrasPublicasDetalhes}
        exact
      />
      <Route path="/orcamento" component={Orcamento} />
      <Route path="/organograma" component={Organograma} />
      {createProtectedRoute('/patrimonio', Patrimonio)}
      <Route path="/patrimonio/detalhes" component={PatrimonioDetalhes} exact />
      <Route path="/perguntas" component={Perguntas} />
      {createProtectedRoute('/processos/sigilosos', ProcessosSigilosos)}
      {createProtectedRoute('/avaliacao-ppa', AvaliacaoPPA)}
      <Route path="/publicacoes" component={Publicacoes} exact />
      <Route path="/publicacoes/:idGrupo" component={Publicacoes} exact />
      <Route
        path="/publicacoes/:idGrupo/:idSubGrupo"
        component={Publicacoes}
        exact
      />
      <Route
        path="/receita/arrecadacoes/bairros"
        component={ArrecadacaoPorBairros}
      />
      <Route path="/receita/arrecadacoes" component={Arrecadacao} />
      <Route path="/receita" component={Receita} exact />
      <Route path="/receita-fonte-recursos" component={ReceitaPorFonte} exact />
      <Route
        path="/receita-fonte-recursos/detalhes"
        component={DetalheReceitasPorFonte}
        exact
      />
      {createProtectedRoute('/receitas-covid', ReceitaCovid)}
      <Route path="/receita/dias" component={ReceitaDia} exact />
      <Route path="/receita/dias/razao" component={ReceitaRazao} exact />
      <Route path="/repasses-despesa/detalhes" component={RepassesDetalhes} />
      <Route path="/repasses-receita/detalhes" component={RepassesDetalhes} />
      <Route path="/repasses-despesa/:tipoRepasse" component={Repasses} />
      <Route path="/repasses-receita/:tipoRepasse" component={Repasses} />
      <Route path="/responsaveis" component={Responsavel} />
      {createProtectedRoute(
        '/servidores',
        menuState.paramIntegradoOxyFolha === 'S' ? ServidoresOxy : Servidores
      )}
      {createProtectedRoute('/servidores/aposentados', Servidores)}
      <Route
        path="/servidores/detalhes"
        component={
          menuState.paramIntegradoOxyFolha === 'S'
            ? ServidorDetalheOxy
            : ServidorDetalhe
        }
      />
      <Route path="/suprimentos-fundos" component={SuprimentosFundos} exact />
      {createProtectedRoute('/veiculos', Veiculos)}
      <Route path="/veiculos/detalhes" component={VeiculosDetalhe} exact />
      <Route path="/despesa/:nivelDespesa" component={Despesa} />
      {createProtectedRoute('/quadro-pessoal', FormQuadroPessoal)}
      {createProtectedRoute('/servidores-covid', Servidores)}
      {createProtectedRoute('/afastamento', Afastamento)}
      <Route path="/entidade" component={EntidadePrincipal} />
      <Route path="/menu" component={Menu} />
      <Route path="/pesquisa" component={Pesquisa} />
      <Route path="/mapa-do-site" component={MapaSite} />
      <Route path="/teclas-de-atalho" component={HotKeys} />
      <Route path="/manutencao" component={EmManutencao} />
      <Route path="/glossario" component={Glossario} />
      {createProtectedRoute('/emendas-parlamentares', EmendaParlamentar)}
      {createProtectedRoute(
        '/emendas-parlamentares/:id',
        EmendaParlamentarDetalhe
      )}
      <Route path="/acessibilidade" component={Acessibilidade} />
      <Route
        path="/fila-espera-atendimento"
        exact
        component={filaEsperaAtendimento}
      />
      <Route
        path="/fila-espera-atendimento/:idFila"
        component={filaEsperaPaciente}
      />
      <Route path="/escalas-profissionais" component={FormEscalas} />
      <Route path="/conselhos" component={FormConselhos} exact />
      <Route path="/conselhos/:idConselho" component={FormConselhoDetalhe} />
      <Route path="/documentos" component={FormDocumentos} />
      <Route path="/horas-extras" component={FormHorasExtras} exact />
      <Route path="/horas-extras/detalhes" component={HorasExtrasDetalhes} />
      <Route path="/homologacoes/detalhes" component={HomologacaoDetalhes} />
      <Route path="/aditivos/detalhes" component={AditivosDetalhes} exact />
      <Route path="/adjudicacoes/detalhes" component={AdjudicacaoDetalhes} />
      <Route
        path="/agenda-previsao-pagamento"
        component={AgendaPrevisaoPagamentos}
      />
      <Route path="/admin" component={RedirectAdminPage} />
      <Route
        path="/estagiarios/detalhes"
        component={
          menuState.paramIntegradoOxyFolha === 'S'
            ? EstagiariosOxy
            : Estagiarios
        }
      />
      <Route path="/inativos/detalhes" component={Inativos} />
      <Route path="/pensionistas/detalhes" component={Pensionistas} />
      <Route
        path="/autonomos/detalhes"
        component={
          menuState.paramIntegradoOxyFolha === 'S' ? AutonomosOxy : Autonomos
        }
      />
      {createProtectedRoute(
        '/participantes-licitacao',
        LicitacoesParticipantes
      )}
      <Route
        path="/participantes-licitacao/detalhes"
        component={LicitacaoParticipantesDetalhe}
      />
      {createProtectedRoute('/subvencao', Subvencoes)}
      <Route path="/subvencao/detalhe" component={SubvencaoDetalhe} />
      <Route
        path="/subvencao/adiantamento-detalhes"
        component={AdiantamentoDetalhes}
      />
      {createProtectedRoute('/alvara', Alvaras)}
      <Route path="/alvara/detalhe" component={AlvaraDetalhe} />
      {createProtectedRoute('/em-liquidacao', EmLicitacao)}
      {createProtectedRoute('/nota-extraorcamentaria', NotaExtraorcamentaria)}
      <Route
        path="/nota-extraorcamentaria/detalhe"
        component={NotaExtraorcamentariaDetalhe}
        exact
      />
      <Route path="/receita-detalhada" component={ReceitaDetalhada} />
      <Route path="/despesa-detalhada" component={DespesaDetalhada} />
      <Route path="/autorizacoes/detalhes" component={AutorizacaoDetalhes} />
      <Route path="/selo" component={FormSelo} />
      <Route path="/creditosadicionais" component={CreditosAdicionais} />
      <Route
        path="/subvencoes/prestacao-contas/detalhe"
        component={PrestacaoContasDetalhe}
      />
      <Route path="/subvencoes/aditivo/detalhe" component={AditivoDetalhe} />
      <Route path="/diario-oficial" component={FormDiario} />
      <Route path="/contatos-covid" component={FormContatoCovid} />
      <Route path="/audiencias-publicas" component={AudienciasPublicas} exact />
      <Route
        path="/audiencias-publicas/detalhes"
        component={AudienciasPublicasDetalhes}
        exact
      />
      <Route path="/carta-servicos" component={CartaServicos} exact />
      <Route path="/dados-abertos" component={DadosAbertos} exact />
      <Route path="/verbas" component={FormVerbas} exact />
      <Route path="/verbas/detalhes" component={VerbasDetalhes} exact />
      <Route
        path="/tributos/renuncia-receitas"
        component={RenunciaReceitasPage}
        exact
      />
      <Route path="/cotas-parlamentares" component={CotasParlamentares} exact />
      {createProtectedRoute('/terceirizados', Terceirizados)}
      <Route
        path="/contab/renuncia-receitas"
        component={ContabRenunciaReceitas}
        exact
      />
      <Route
        path="/contab/renuncia-receitas/detalhes"
        component={ContabRenunciaReceitasDetalhe}
        exact
      />
      <Route path="/restos-a-pagar" component={RestosAPagar} exact />
      <Route path="/recursos/detalhes/" component={RecursoDetalhe} />
      {createProtectedRoute('/pca', Pca)}
      <Route path="/pca/detalhe" component={PcaDetalhe} />
      {createProtectedRoute('/acordos-sem-recurso', AcordoSemRecurso)}
      {createProtectedRoute(
        '/acordos-sem-recurso/:id',
        AcordoSemRecursoDetalhe
      )}
      {createProtectedRoute(
        '/estrutura-organizacional',
        FormEstruturaOrganizacional
      )}
      <Route
        path="/estrutura-organizacional/:id"
        component={FormEstruturaOrganizacionalDetail}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
