export const RENUNCIA_RECEITA = 'RENUNCIA_RECEITA';
export const RENUNCIA_RECEITA_START = 'RENUNCIA_RECEITA_START';
export const RENUNCIA_RECEITA_SUCCESS = 'RENUNCIA_RECEITA_SUCCESS';
export const RENUNCIA_RECEITA_ERROR = 'RENUNCIA_RECEITA_ERROR';

export const RENUNCIA_RECEITA_DETALHE = 'RENUNCIA_RECEITA_DETALHE';
export const RENUNCIA_RECEITA_DETALHE_START = 'RENUNCIA_RECEITA_DETALHE_START';
export const RENUNCIA_RECEITA_DETALHE_SUCCESS =
  'RENUNCIA_RECEITA_DETALHE_SUCCESS';
export const RENUNCIA_RECEITA_DETALHE_ERROR = 'RENUNCIA_RECEITA_DETALHE_ERROR';
