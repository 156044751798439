import React from 'react';
import Component from 'react-pure-render/component';
import { onChange, resetCrud } from 'portaltransparencia-common/crud/Actions';
import { connect } from 'react-redux';
import * as actions from './Actions';
import { Map } from 'immutable';
import { Panel } from '@elotech/components';
import PropTypes from 'prop-types';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import { Col, Row } from 'react-bootstrap';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react';
import Tab from 'portaltransparencia-common/components/Tab.react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import configuration from 'portaltransparencia-common/config/Config';
import PageHeader from '../components/PageHeader.react';
import NotasExplicativas from '../notaexplicativa/Form.react';
import storageService from 'portaltransparencia-common/libs/StorageService';
import SuspensaoFornecedoresTable from './SuspensaoFornecedorList.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { downloadArquivo } from '../arquivo/Actions.js';

export class FormSuspensaoFornecedor extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchSuspensaoFornecedores: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;

    this.searchWithPage(page);
  }

  searchWithPage = page => {
    const { searchSuspensaoFornecedores } = this.props;
    searchSuspensaoFornecedores(this.montaFilter(page));
  };

  montaFilter = function (page) {
    const { filtros } = this.props;
    let filterCnpjCpf;

    let filter = 'somenteSuspensos=true&fornecedor=S';

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    if (filtros.get('cnpjCpf')) {
      filterCnpjCpf = filtros.get('cnpjCpf');
      filter = addFilterUrl(
        filter,
        `cnpjCpf=${filterCnpjCpf.replace(/\D/g, '')}`
      );
    }

    if (filtros.get('inicioDataIniSuspensao')) {
      filter = addFilterUrl(
        filter,
        `inicioDataIniSuspensao=${filtros.get('inicioDataIniSuspensao')}`
      );
    }

    if (filtros.get('fimDataIniSuspensao')) {
      filter = addFilterUrl(
        filter,
        `fimDataIniSuspensao=${filtros.get('fimDataIniSuspensao')}`
      );
    }

    if (filtros.get('inicioDataFinSuspensao')) {
      filter = addFilterUrl(
        filter,
        `inicioDataFinSuspensao=${filtros.get('inicioDataFinSuspensao')}`
      );
    }

    if (filtros.get('fimDataFinSuspensao')) {
      filter = addFilterUrl(
        filter,
        `fimDataFinSuspensao=${filtros.get('fimDataFinSuspensao')}`
      );
    }

    if (filtros.get('observacao')) {
      filter = addFilterUrl(filter, `observacao=${filtros.get('observacao')}`);
    }

    if (filtros.get('situacao')) {
      filter = addFilterUrl(filter, `situacao=${filtros.get('situacao')}`);
    }

    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroFornecedores', event);
  };

  onConsultaFornecedores = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    event && event.stopPropagation();

    this.refs.panelFiltroFornecedores &&
      this.refs.panelFiltroFornecedores.collapse();
  };

  onResetFiltro = event => {
    const { resetCrud, searchSuspensaoFornecedores } = this.props;
    event && event.preventDefault();
    event && event.stopPropagation();
    resetCrud('filtroFornecedores');
    searchSuspensaoFornecedores('somenteSuspensos=true&fornecedor=S');
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { entidade } = storageService.getItem('userInfo');
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/pessoaadm/report?entidade=${entidade.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      page,
      location,
      filtros,
      downloadArquivo,
      isIntegradoOxy
    } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar"
          mobileClose
          isForm
          ref="panelFiltroFornecedores"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="nome"
                  placeholder="Informe o nome do fornecedor"
                  name="nome"
                  ref="nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                ></Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="cnpjCpf"
                  placeholder="Informe o CNPJ/CPF do fornecedor"
                  name="cnpjCpf"
                  ref="cnpjCpf"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpjCpf')}
                ></Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="inicioDataIniSuspensao"
                  name="inicioDataIniSuspensao"
                  ref="inicioDataIniSuspensao"
                  label="Início da Data Inicial da Suspensão"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('inicioDataIniSuspensao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="fimDataIniSuspensao"
                  name="fimDataIniSuspensao"
                  ref="fimDataIniSuspensao"
                  label="Fim da Data Inicial da Suspensão"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('fimDataIniSuspensao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="inicioDataFinSuspensao"
                  name="inicioDataFinSuspensao"
                  ref="inicioDataFinSuspensao"
                  label="Início da Data Final da Suspensão"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('inicioDataFinSuspensao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="fimDataFinSuspensao"
                  name="fimDataFinSuspensao"
                  ref="fimDataFinSuspensao"
                  label="Fim da Data Final da Suspensão"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('fimDataFinSuspensao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={6}>
                <Input
                  id="observacao"
                  name="observacao"
                  ref="observacao"
                  label="Observação"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('observacao')}
                ></Input>
              </Col>
              {!isIntegradoOxy && (
                <Col xs={12} sm={4} md={3}>
                  <Input
                    name="situacao"
                    ref="situacao"
                    label="Situacao"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('situacao')}
                  >
                    <option value="">Selecione</option>
                    <option key={1} value={1}>
                      Inabilitado
                    </option>
                    <option key={2} value={2}>
                      Inadimplente
                    </option>
                    <option key={3} value={3}>
                      Inabilitado Rescisão
                    </option>
                    <option key={4} value={4}>
                      Inabilitado Suspenso
                    </option>
                    <option key={5} value={5}>
                      Inabilitado Outros
                    </option>
                  </Input>
                </Col>
              )}
              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultaFornecedores}
                    >
                      pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline np-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoDataConsulta />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab">
            <Tab
              title="Fornecedores Suspensos"
              name="suspensaoFornecedores"
              onlyTable
              active
            >
              <SuspensaoFornecedoresTable
                page={page}
                searchWithPage={this.searchWithPage}
                onDownload={downloadArquivo}
                isIntegradoOxy={isIntegradoOxy}
              />
              <SearchPagination
                page={page}
                searchWithPage={this.searchWithPage}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.suspensaoFornecedor.get('suspensaoFornecedores'),
    filtros: state.crud.getIn(['filtroFornecedores', 'currentRecord'], Map({})),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormSuspensaoFornecedor);
