import * as types from './Types.js';

export function findRenunciaReceita(filtro) {
  return ({ fetch }) => ({
    type: types.RENUNCIA_RECEITA,
    payload: {
      promise: fetch('/api/contab/renuncia-receitas?' + filtro)
    }
  });
}

export function findRenunciaReceitaDetalhes(filtro) {
  return ({ fetch }) => ({
    type: types.RENUNCIA_RECEITA_DETALHE,
    payload: {
      promise: fetch('/api/contab/renuncia-receitas/detalhes?' + filtro)
    }
  });
}
