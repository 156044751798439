import PropTypes from 'prop-types';
import React from 'react';
import ModalMessage from './ModalMessage.react.js';
import TableActionButton from './TableActionButton.react.js';

export default class TableActions extends React.PureComponent {
  static propTypes = {
    customActions: PropTypes.node,
    data: PropTypes.any.isRequired,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    onView: PropTypes.func,
    onSelect: PropTypes.func,
    onDownload: PropTypes.func,
    onMore: PropTypes.func,
    onCopyUrl: PropTypes.func,
    editTitle: PropTypes.string,
    removeTitle: PropTypes.string,
    viewTitle: PropTypes.string,
    selectTitle: PropTypes.string,
    downloadTitle: PropTypes.string,
    moreTitle: PropTypes.string,
    duplicateTitle: PropTypes.string,
    showView: PropTypes.bool,
    showDownload: PropTypes.bool
  };

  static defaultProps = {
    editTitle: 'Editar',
    removeTitle: 'Remover',
    viewTitle: 'Visualizar',
    copyUrlTitle: 'Copiar',
    selectTitle: 'Selecionar',
    downloadTitle: 'Download',
    moreTitle: 'Mais Detalhes',
    duplicateTitle: 'Duplicar',
    showView: true,
    showDownload: true
  };

  constructor(props) {
    super(props);
    this.state = { showRemove: false };
  }

  onEdit = () => {
    const { onEdit, data } = this.props;

    onEdit(data);
  };

  onRemove = () => {
    const { onRemove, data } = this.props;

    this.setState({ showRemove: true, data: data });

    onRemove(data);
  };

  onView = () => {
    const { onView, data } = this.props;

    onView(data);
  };

  onSelect = () => {
    const { onSelect, data } = this.props;

    onSelect(data);
  };

  onDownload = () => {
    const { onDownload, data } = this.props;

    onDownload(data);
  };

  onMore = () => {
    const { onMore, data } = this.props;

    onMore(data);
  };

  onDuplicate = () => {
    const { onDuplicate, data } = this.props;

    onDuplicate(data);
  };

  onConfirmRemove = () => {
    const { data } = this.props;

    this.setState({ showRemove: true, data: data });
  };

  onCancelRemove = () => {
    this.setState({ showRemove: false });
  };

  onCopyUrl = () => {
    const { onCopyUrl, data } = this.props;

    onCopyUrl(data);
  };

  renderModalMessage = () => {
    const { data } = this.props;

    return (
      <ModalMessage
        ref="modalMessageRemove"
        title="Apagar?"
        onClose={this.onRemove}
        onCancel={this.onCancelRemove}
        type="warning"
        showCancelButton={true}
        sender={data}
      />
    );
  };

  render() {
    const {
      onEdit,
      editTitle,
      onRemove,
      removeTitle,
      onView,
      viewTitle,
      showView,
      onSelect,
      selectTitle,
      showDownload,
      onDownload,
      downloadTitle,
      onMore,
      onCopyUrl,
      copyUrlTitle,
      moreIcon,
      moreTitle,
      onDuplicate,
      duplicateTitle,
      data
    } = this.props;

    return (
      <tr ref="row">
        {this.props.children}
        <td>
          <div className="btn-actions">
            <div className="btn-actions-inner">
              {onDuplicate && (
                <TableActionButton
                  ref="actionButtonView"
                  data={data}
                  title={duplicateTitle}
                  icon="fa fa-copy"
                  onClick={onDuplicate}
                />
              )}
              {onCopyUrl && (
                <TableActionButton
                  ref="actionButtonView"
                  data={data}
                  title={copyUrlTitle}
                  icon="fa fa-clipboard"
                  onClick={onCopyUrl}
                />
              )}
              {showView && onView && (
                <TableActionButton
                  ref="actionButtonView"
                  data={data}
                  title={viewTitle}
                  icon="fa fa-eye"
                  onClick={onView}
                />
              )}
              {onEdit && (
                <TableActionButton
                  ref="actionButtonEdit"
                  data={data}
                  title={editTitle}
                  icon="fa fa-pencil"
                  onClick={onEdit}
                />
              )}
              {onRemove && (
                <TableActionButton
                  ref="actionButtonRemove"
                  data={data}
                  title={removeTitle}
                  icon="fa fa-trash"
                  onClick={this.onConfirmRemove}
                />
              )}
              {this.state.showRemove && this.renderModalMessage()}
              {onSelect && (
                <TableActionButton
                  ref="actionButtonSelect"
                  data={data}
                  title={selectTitle}
                  icon="fa fa-plus"
                  onClick={onSelect}
                />
              )}
              {showDownload && onDownload && data && (
                <TableActionButton
                  ref="actionButtonDownload"
                  data={data}
                  title={downloadTitle}
                  icon="fa fa-download"
                  onClick={onDownload}
                />
              )}
              {onMore && (
                <TableActionButton
                  ref="actionButtonMore"
                  data={data}
                  title={moreTitle}
                  icon={moreIcon || 'fa fa-chevron-right'}
                  onClick={onMore}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
