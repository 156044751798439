export const FIND_TIPOESIC = 'FIND_TIPOESIC';
export const FIND_TIPOESIC_START = 'FIND_TIPOESIC_START';
export const FIND_TIPOESIC_SUCCESS = 'FIND_TIPOESIC_SUCCESS';
export const FIND_TIPOESIC_ERROR = 'FIND_TIPOESIC_ERROR';

export const FIND_TIPOSIC = 'FIND_TIPOSIC';
export const FIND_TIPOSIC_START = 'FIND_TIPOSIC_START';
export const FIND_TIPOSIC_SUCCESS = 'FIND_TIPOSIC_SUCCESS';
export const FIND_TIPOSIC_ERROR = 'FIND_TIPOSIC_ERROR';

export const RESET_CONSULTA_ESIC = 'RESET_CONSULTA_ESIC';

export const ESIC = 'ESIC';
export const ESIC_START = 'ESIC_START';
export const ESIC_SUCCESS = 'ESIC_SUCCESS';
export const ESIC_ERROR = 'ESIC_ERROR';

export const PROCESSO = 'PROCESSO';
export const PROCESSO_START = 'PROCESSO_START';
export const PROCESSO_SUCCESS = 'PROCESSO_SUCCESS';
export const PROCESSO_ERROR = 'PROCESSO_ERROR';

export const TOTAL_ESIC = 'TOTAL_ESIC';
export const TOTAL_ESIC_START = 'TOTAL_ESIC_START';
export const TOTAL_ESIC_SUCCESS = 'TOTAL_ESIC_SUCCESS';
export const TOTAL_ESIC_ERROR = 'TOTAL_ESIC_ERROR';

export const FIND_SITUACOES = 'FIND_SITUACOES';
export const FIND_SITUACOES_START = 'FIND_SITUACOES_START';
export const FIND_SITUACOES_SUCCESS = 'FIND_SITUACOES_SUCCESS';
export const FIND_SITUACOES_ERROR = 'FIND_SITUACOES_ERROR';
