import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class ArrecadacaoDespesa extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    arrecadacaoDespesaMes: PropTypes.object.isRequired,
    arrecadadoSobreEmpenhado: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired,
    tipoEntidadeLegislativa: PropTypes.bool.isRequired,
    paramExibirRepasses: PropTypes.string.isRequired,
    paramExibirExecDespCompleta: PropTypes.string.isRequired
  };

  getDataChartBarFull = data => {
    const dataChart = [
      [
        'Mês',
        'Arrecadado',
        'Empenhado',
        'Liquidado',
        'Pago',
        'Repasses Recebidos',
        'Repasses Enviados'
      ]
    ];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado'),
            item.get('valorEmpenhado'),
            item.get('valorLiquidado'),
            item.get('valorPago'),
            item.get('repasseRecebido'),
            item.get('repasseConcedido')
          ];
        })
        .toJS()
    );
  };

  getDataChartBarWithRepasses = data => {
    const dataChart = [
      [
        'Mês',
        'Arrecadado',
        'Empenhado',
        'Repasses Recebidos',
        'Repasses Enviados'
      ]
    ];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado'),
            item.get('valorEmpenhado'),
            item.get('repasseRecebido'),
            item.get('repasseConcedido')
          ];
        })
        .toJS()
    );
  };

  getDataChartBarWithoutRepasses = data => {
    const dataChart = [['Mês', 'Arrecadado', 'Empenhado', 'Liquidado', 'Pago']];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado'),
            item.get('valorEmpenhado'),
            item.get('valorLiquidado'),
            item.get('valorPago')
          ];
        })
        .toJS()
    );
  };

  getDataChartBarDefault = data => {
    const dataChart = [['Mês', 'Arrecadado', 'Empenhado']];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado'),
            item.get('valorEmpenhado')
          ];
        })
        .toJS()
    );
  };

  getOptions = () => {
    let { width } = this.props;
    if (width > 400) {
      width = width * 0.8;
    }

    return {
      bar: { groupWidth: '90%' },
      legend: { position: 'top' },
      width: width,
      height: 300
    };
  };

  getDataChart = () => {
    const {
      arrecadacaoDespesaMes,
      paramExibirRepasses,
      paramExibirExecDespCompleta
    } = this.props;

    if (paramExibirExecDespCompleta === 'S' && paramExibirRepasses === 'S') {
      return this.getDataChartBarFull(arrecadacaoDespesaMes);
    } else if (
      paramExibirExecDespCompleta === 'N' &&
      paramExibirRepasses === 'S'
    ) {
      return this.getDataChartBarWithRepasses(arrecadacaoDespesaMes);
    } else if (
      paramExibirExecDespCompleta === 'S' &&
      paramExibirRepasses === 'N'
    ) {
      return this.getDataChartBarWithoutRepasses(arrecadacaoDespesaMes);
    } else {
      return this.getDataChartBarDefault(arrecadacaoDespesaMes);
    }
  };

  render() {
    const {
      arrecadacaoDespesaMes,
      arrecadadoSobreEmpenhado,
      exercicio,
      tipoEntidadeLegislativa
    } = this.props;

    const options = this.getOptions();

    const dataChart = this.getDataChart();

    const red = '#c7382d';
    const green = '#659937';
    let color = green;
    if (arrecadadoSobreEmpenhado < 0) {
      color = red;
    }

    return (
      <Widget
        icon="fa fa-bar-chart"
        isChart
        title={`Arrecadação / Despesa de ${exercicio}`}
      >
        <Row>
          <Col xs={12} sm={9} md={9}>
            {arrecadacaoDespesaMes.size > 0 && (
              <Chart
                ref="chart"
                chartType="ColumnChart"
                data={dataChart}
                legend_toggle
                graphID="dashboard-bar-chart"
                options={options}
                width={`${options.width}px`}
                chartLanguage="pt-br"
              />
            )}
          </Col>
          {!tipoEntidadeLegislativa && (
            <Col xs={12} sm={3} md={3}>
              <div style={{ height: '300px', display: 'table' }}>
                <article
                  className="widget-numeric"
                  style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    padding: '0 20px 0 0'
                  }}
                >
                  <b style={{ color: color }} className="widget-numeric-number">
                    {arrecadadoSobreEmpenhado &&
                      NumberUtils.format(arrecadadoSobreEmpenhado) + '%'}
                  </b>
                  <p className="widget-numeric-subtitle">
                    Variação da Arrecadação sobre o Empenhado
                  </p>
                </article>
              </div>
            </Col>
          )}
        </Row>
      </Widget>
    );
  }
}

export default ArrecadacaoDespesa;
