import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class CargoDetalheInformacao extends Component {
  static propTypes = {
    cargo: PropTypes.object.isRequired
  };

  render() {
    const { cargo } = this.props;

    return (
      <Panel title={cargo.nivel} expansible={false}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Cargo"
              value={cargo.descricao}
              id="cargo"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Quadro Salarial"
              value={cargo.quadroSalarial}
              id="quadroSalarial"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Grupo Ocupacional"
              value={cargo.grupoOcupacional}
              id="grupoOcupacional"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Nível"
              value={cargo.nivel}
              id="nivel"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Vagas total"
              value={cargo.vagasTotal}
              id="vagasTotal"
            />
            <DisplayDataItem
              sm={6}
              title="Vagas ocupadas"
              value={cargo.vagasOcupadas}
              id="vagasOcupadas"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default CargoDetalheInformacao;
