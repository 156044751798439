import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import ObraDetalheMedicaoImagem from './ObraDetalheMedicaoImagem.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import ObraDetalheMedicaoArquivo from './ObraDetalheMedicaoArquivo.react.js';
import { fromJS } from 'immutable';

export class ObraDetalheMedicao extends Component {
  static propTypes = {
    medicoes: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    medicaoArquivos: PropTypes.object.isRequired,
    findArquivosMedicao: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showImagens: false,
      showFiles: false,
      actualMedicaoArquivos: [],
      actualImagens: [],
      actualDownloads: []
    };
  }

  renderMedicoes = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('idOrdemServico') +
      '_' +
      data.get('sequencia');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onView={data => this.onShowImages(data)}
        onDownload={data => this.onDownload(data)}
        showView={data.get('existeImagem') > 0}
        showDownload={data.get('existeArquivo') > 0}
      >
        <td>{data.get('aditivo')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        {!this.possuiDataPublicacao || (
          <td>{DateUtils.formatDateTimeShort(data.get('dataPublicacao'))}</td>
        )}
        <td>{data.get('tipoAcompanhamento')}</td>
        <td>{NumberUtils.format(data.get('percentual'))} %</td>
        <td>{NumberUtils.format(data.get('acumulado'))} %</td>
      </TableActions>
    );
  };

  getChaveItem = item => {
    return (
      item.get('idOxy') ||
      `${item.get('entidade')}_${item.get('exercicio')}_${item.get(
        'idOrdemServico'
      )}_${item.get('sequencia')}`
    );
  };

  adicionaMedicaoItem = item => {
    const { medicaoArquivos } = this.props;
    const { actualMedicaoArquivos } = this.state;

    const medicaoItem = {
      chave: this.getChaveItem(item),
      arquivos: medicaoArquivos
    };

    if (item.get('idArquivoLaudo')) {
      medicaoItem.arquivos = fromJS([
        ...medicaoItem.arquivos,
        fromJS({
          arquivoLaudo: true,
          idarquivo: item.get('idArquivoLaudo'),
          nomeArquivo: item.get('nomeArquivoLaudo')
        })
      ]);
    }

    this.setState({
      actualMedicaoArquivos: [...actualMedicaoArquivos, medicaoItem]
    });

    this.atualizaArquivos(medicaoItem);
  };

  atualizaArquivos = medicaoItem => {
    const regex = /\.(jpg|png|bmp|jpeg)$/i;

    this.setState({
      actualDownloads: medicaoItem.arquivos.filter(
        arq => !arq.get('nomeArquivo')?.match(regex) || arq.get('arquivoLaudo')
      ),
      actualImagens: medicaoItem.arquivos.filter(
        arq => arq.get('nomeArquivo')?.match(regex) && !arq.get('arquivoLaudo')
      )
    });
  };

  atualizaMedicaoArquivos = item => {
    const { findArquivosMedicao } = this.props;
    const { actualMedicaoArquivos } = this.state;
    const chave = this.getChaveItem(item);

    const medicaoItem = actualMedicaoArquivos.find(obj => obj.chave === chave);

    if (medicaoItem) {
      this.atualizaArquivos(medicaoItem);
      return;
    }

    findArquivosMedicao(
      item.get('entidade'),
      item.get('exercicio'),
      item.get('idOrdemServico'),
      item.get('sequencia'),
      item.get('idOxy')
    ).then(() => this.adicionaMedicaoItem(item));
  };

  onShowImages = item => {
    this.atualizaMedicaoArquivos(item);
    this.openImages();
  };

  openImages = () => {
    this.setState({
      showImagens: true
    });
  };

  onCloseImages = () => {
    this.setState({
      showImagens: false,
      actualImagens: [],
      actualDownloads: []
    });
  };

  openFiles = () => {
    this.setState({
      showFiles: true
    });
  };

  onCloseFiles = () => {
    this.setState({
      showFiles: false,
      actualImagens: [],
      actualDownloads: []
    });
  };

  onDownload = item => {
    this.atualizaMedicaoArquivos(item);
    this.openFiles();
  };

  render() {
    const { medicoes } = this.props;
    const {
      showImagens,
      showFiles,
      actualDownloads,
      actualImagens
    } = this.state;
    this.possuiDataPublicacao =
      medicoes.filter(m => m.get('dataPublicacao')).length > 0;

    return (
      <section>
        <ObraDetalheMedicaoImagem
          imagens={fromJS(actualImagens)}
          show={showImagens}
          onClose={this.onCloseImages}
        />
        <ObraDetalheMedicaoArquivo
          arquivos={fromJS(actualDownloads)}
          show={showFiles}
          onClose={this.onCloseFiles}
        />
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={medicoes.isEmpty()}>
              <tr>
                <th>Contrato/Aditivo</th>
                <th>Data</th>
                {!this.possuiDataPublicacao || <th>Data Publicação</th>}
                <th>Tipo Acompanhamento</th>
                <th>Medição</th>
                <th>Acumulado</th>
                <th style={{ textAlign: 'right', width: '100px' }}>Arquivos</th>
              </tr>
            </TableHeader>
            <tbody>{medicoes.map(this.renderMedicoes)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ObraDetalheMedicao;
