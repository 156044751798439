import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import Cargo from './Cargo.react.js';

export class CargosTable extends Component {
  static propTypes = {
    filter: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    paramMostraAto: PropTypes.string
  };

  generateKey = cargo => {
    return `${cargo.get('cargo')}_
            ${cargo.get('quadroSalarial')}_
            ${cargo.get('grupoOcupacional')}_
            ${cargo.get('nivel')}`;
  };

  renderCargos = cargo => {
    return (
      <Cargo
        key={this.generateKey(cargo)}
        cargo={cargo}
        history={this.props.history}
        paramMostraAto={this.props.paramMostraAto}
      />
    );
  };

  render() {
    const { page, searchWithPage, paramMostraAto } = this.props;
    const cargos = page.get('content');

    return (
      <div>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={cargos.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="descricao"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="quadroSalarialDesc"
                  label="Quadro Salarial"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="grupoOcupacionalDesc"
                  label="Grupo Ocupacional"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="nivelDesc"
                  label="Nível"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="vagasTotal"
                  label="Vagas total"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="vagasOcupadas"
                  label="Vagas ocupadas"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                {paramMostraAto == 'S' && (
                  <TableHeaderItem
                    id="numeroAto"
                    field="numeroAto"
                    label="Nº Ato"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                {paramMostraAto == 'S' && (
                  <TableHeaderItem
                    id="anoAto"
                    field="anoAto"
                    label="Ano Ato"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{cargos.map(this.renderCargos)}</tbody>
          </table>
        </div>
        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </div>
    );
  }
}

export default CargosTable;
