import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import { hasArquivo } from '../licitacoes/Arquivo';
import * as actions from './Actions.js';
import SubvencaoAditivos from './FormDetalheAditivos.react.js';
import SubvencaoAnexos from './FormDetalheAnexo.react.js';
import SubvencaoPrestacaoContas from './FormDetalheContas.react.js';
import SubvencaoRemuneracoes from './FormDetalheRemuneracao.react.js';
import SubvencaoValoresLiberados from './FormDetalheValores.react.js';

export class FormSubvencoesDetalhe extends Component {
  static propTypes = {
    subvencao: PropTypes.object.isRequired,
    valoresLiberados: PropTypes.object.isRequired,
    prestacaoContas: PropTypes.object.isRequired,
    aditivos: PropTypes.object.isRequired,
    remuneracoes: PropTypes.object.isRequired,
    anexos: PropTypes.object.isRequired,
    findSubvencaoDetalhes: PropTypes.func.isRequired,
    findSubvencaoValoresLiberados: PropTypes.func.isRequired,
    findSubvencaoPrestacaoContas: PropTypes.func.isRequired,
    findSubvencaoAditivos: PropTypes.func.isRequired,
    findSubvencaoRemuneracoes: PropTypes.func.isRequired,
    findSubvencaoAnexos: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      location,
      findSubvencaoDetalhes,
      findSubvencaoValoresLiberados,
      findSubvencaoPrestacaoContas,
      findSubvencaoAditivos,
      findSubvencaoRemuneracoes,
      findSubvencaoAnexos
    } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);
    let filtro = `entidade=${params.entidade}&exercicio=${params.exercicio}&subvencao=${params.subvencao}&anoSubvencao=${params.anoSubvencao}&tipo=${params.idTipo}`;

    findSubvencaoDetalhes(filtro);
    findSubvencaoValoresLiberados(filtro);
    findSubvencaoPrestacaoContas(filtro);
    findSubvencaoAditivos(filtro);
    findSubvencaoRemuneracoes(filtro);
    findSubvencaoAnexos(filtro);
  }

  renderLicitacoes = data => {
    const { downloadArquivo, subvencao } = this.props;
    const key = data.get('pessoa') + '_' + data.get('licitacao');
    const showColArquivo = hasArquivo(subvencao.get('licitacoes'));

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data.get('idArquivo') && downloadArquivo}
      >
        <td>{data.get('modalidade')}</td>
        <td>{`${data.get('licitacao')}/${data.get('exercicio')}`}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataAbertura'))}</td>
        {showColArquivo && <td>{data.get('numeroContrato')}</td>}
      </TableActions>
    );
  };

  onViewDenuncia = () => {
    this.props.history.push('/esic');
  };

  render() {
    const {
      subvencao,
      valoresLiberados,
      prestacaoContas,
      aditivos,
      remuneracoes,
      anexos,
      location,
      downloadArquivo,
      history
    } = this.props;
    const valorPrevisto = !subvencao.get('valorPrevisto')
      ? 0
      : subvencao.get('valorPrevisto');
    const valorLiberado = !subvencao.get('valorLiberado')
      ? 0
      : subvencao.get('valorLiberado');
    const saldo = valorPrevisto - valorLiberado;
    const valorAditivo = !subvencao.get('valorAditivo')
      ? 0
      : subvencao.get('valorAditivo');
    const saldoAtualizado = saldo + valorAditivo;
    const valorAtual = valorPrevisto + valorAditivo;

    return (
      <section>
        <PageHeader location={location} />
        <BotaoImprimir />

        <Panel
          title={`Subvenção: ${subvencao.get('subvencao')}/${subvencao.get(
            'anoSubvencao'
          )}`}
        >
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Subvenção/Ano"
                value={`${subvencao.get('subvencao')}/${subvencao.get(
                  'anoSubvencao'
                )}`}
                id="subvencao"
              />
              <DisplayDataItem
                sm={8}
                title="Orgão da Administração Pública"
                value={subvencao.get('orgao')}
                id="orgao"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Número SIT"
                value={subvencao.get('numerosit')}
                id="numerosit"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="CNPJ - OSC"
                value={subvencao.get('cnpj')}
                id="cnpjBeneficiario"
              />
              <DisplayDataItem
                sm={8}
                title="Organização Social Civil - OSC"
                value={subvencao.get('nomeBeneficiario')}
                id="nomeBeneficiario"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Identificação Parceria"
                value={subvencao.get('tipoSubvencao')}
                id="tipoSubvencao"
              />
              <DisplayDataItem
                sm={8}
                title="Objeto"
                value={subvencao.get('objeto')}
                id="objeto"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Data Publicação"
                value={DateUtils.formatDateTimeShort(
                  subvencao.get('dataPublicacao')
                )}
                id="dataPublicacao"
              />
              <DisplayDataItem
                sm={4}
                title="Veiculo Publicação"
                value={subvencao.get('veiculoPublicacao')}
                id="veiculoPublicacao"
              />
              <DisplayDataItem
                sm={4}
                title="Nº Edição"
                value={subvencao.get('nrEdicaoPublicacao')}
                id="nrEdicaoPublicacao"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Data Assinatura"
                value={DateUtils.formatDateTimeShort(
                  subvencao.get('dataCelebracao')
                )}
                id="dataAssinatura"
              />
              <DisplayDataItem
                sm={4}
                title="Início Parceria"
                value={DateUtils.formatDateTimeShort(
                  subvencao.get('dataInicioConvenio')
                )}
                id="dataInicioConvenio"
              />
              <DisplayDataItem
                sm={4}
                title="Término Parceria Inicial"
                value={DateUtils.formatDateTimeShort(
                  subvencao.get('dataVencimentoConvenio')
                )}
                id="dataVencimentoConvenio"
              />
              <DisplayDataItem
                sm={4}
                title="Término Parceria Atual"
                value={DateUtils.formatDateTimeShort(
                  subvencao.get('dataTermino')
                )}
                id="dataTermino"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Valor Inicial"
                value={NumberUtils.format(valorPrevisto)}
                id="valorPrevisto"
              />
              <DisplayDataItem
                sm={4}
                title="Valor Aditivos"
                value={NumberUtils.format(valorAditivo)}
                id="valorAditivo"
              />
              <DisplayDataItem
                sm={4}
                title="Valor Atual"
                value={NumberUtils.format(valorAtual)}
                id="valorAtual"
              />
              <DisplayDataItem
                sm={3}
                title="Valor Liberado"
                value={NumberUtils.format(valorLiberado)}
                id="valorLiberado"
              />
              <DisplayDataItem
                sm={4}
                title="Saldo Atualizado"
                value={NumberUtils.format(saldoAtualizado)}
                id="valorSaldoAtualizado"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Fiscal/Gestor"
                value={subvencao.get('nomeGestorParceria')}
                id="nomeGestorParceria"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
        <Panel expansible={false} isTable tableResponsive>
          <Row>
            <Col xs={9} sm={9} md={9} />
            <Col xs={3} sm={3} md={3}>
              <button
                id="buttonDenuncia"
                className="btn module-color float-right"
                ref="denuncia"
                onClick={this.onViewDenuncia}
              >
                Apresentação de Denúncia
              </button>
            </Col>
          </Row>
          <Tabs ref="tab">
            <Tab title="Valores Liberados" active>
              <h4 className="mt-xs mb-xs center">
                <b>Valores Liberados</b>
              </h4>
              <SubvencaoValoresLiberados valores={valoresLiberados} />
            </Tab>
            <Tab title="Prestação de Contas">
              <h4 className="mt-xs mb-xs center">
                <b>Prestação de Contas</b>
              </h4>
              <SubvencaoPrestacaoContas
                contas={prestacaoContas}
                onDownload={downloadArquivo}
                history={history}
              />
            </Tab>
            <Tab title="Aditivos">
              <h4 className="mt-xs mb-xs center">
                <b>Aditivos</b>
              </h4>
              <SubvencaoAditivos
                aditivos={aditivos}
                onDownload={downloadArquivo}
                history={history}
              />
            </Tab>
            <Tab title="Remuneração">
              <h4 className="mt-xs mb-xs center">
                <b>Remuneração</b>
              </h4>
              <SubvencaoRemuneracoes
                remuneracoes={remuneracoes}
                onDownload={downloadArquivo}
              />
            </Tab>
            <Tab title="Anexos">
              <h4 className="mt-xs mb-xs center">
                <b>Anexos</b>
              </h4>
              <SubvencaoAnexos anexos={anexos} onDownload={downloadArquivo} />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    subvencao: state.subvencao.get('subvencao'),
    valoresLiberados: state.subvencao.get('valoresLiberados'),
    prestacaoContas: state.subvencao.get('prestacaoContas'),
    aditivos: state.subvencao.get('aditivos'),
    remuneracoes: state.subvencao.get('remuneracoes'),
    anexos: state.subvencao.get('anexos'),
    tipoSubvencao: state.subvencao.get('tipoSubvencao')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormSubvencoesDetalhe);
