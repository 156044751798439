import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import ArrecadacaoDespesa from './ArrecadacaoDespesa.react';
import DespesaAno from './DespesaAno.react';
import GastosPorTipo from './GastosPorTipo.react';
import ReceitaAno from './ReceitaAno.react';
import RepasseAno from './RepasseAno.react';
import parametroService from '../parametros/ParametroService.js';
import {
  PARAM_RESUMO_ANUAL_EXIBIR_EXECUCAO_DESP_COMPLETA,
  PARAM_RESUMO_ANUAL_EXIBIR_REPASSES
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia.js';

export class FormDashboard extends Component {
  static propTypes = {
    arrecadacaoDespesaMes: PropTypes.object.isRequired,
    receitaValorAtualizado: PropTypes.number.isRequired,
    despesaValorAtualizado: PropTypes.number.isRequired,
    gastosPessoal: PropTypes.object.isRequired,
    gastosEducacao: PropTypes.object.isRequired,
    receitaDashboard: PropTypes.object.isRequired,
    gastosSaude: PropTypes.object.isRequired,
    findArrecadacaoDespesaMes: PropTypes.func.isRequired,
    findReceitaValorAtualizado: PropTypes.func.isRequired,
    findDespesaValorAtualizado: PropTypes.func.isRequired,
    findGastosPessoal: PropTypes.func.isRequired,
    findGastosEducacao: PropTypes.func.isRequired,
    findGastosSaude: PropTypes.func.isRequired,
    findReceitasDashBoardPorMes: PropTypes.func.isRequired,
    entidadeSelecionada: PropTypes.object,
    location: PropTypes.object.isRequired,
    valorArrecadado: PropTypes.number.isRequired,
    valorEmpenhado: PropTypes.number,
    repasseConcedido: PropTypes.number,
    repasseRecebido: PropTypes.number,
    arrecadadoSobreEmpenhado: PropTypes.number,
    arrecadadoSobrePrevisto: PropTypes.number,
    valorArrecadadoMes: PropTypes.number,
    paramExibirRepasses: PropTypes.string,
    paramExibirExecDespCompleta: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 800
    };
  }

  componentDidMount() {
    const {
      findArrecadacaoDespesaMes,
      findReceitaValorAtualizado,
      findDespesaValorAtualizado,
      findGastosPessoal,
      findGastosEducacao,
      findGastosSaude,
      findReceitasDashBoardPorMes
    } = this.props;
    findArrecadacaoDespesaMes();
    findReceitaValorAtualizado();
    findDespesaValorAtualizado();
    findGastosPessoal();
    findGastosEducacao();
    findGastosSaude();
    findReceitasDashBoardPorMes();

    const node = ReactDOM.findDOMNode(this);

    this.setState({
      width: node.clientWidth
    });
  }

  getDescricaoPessoal = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    return (
      <div style={{ color: '#fff' }}>
        O quadro demonstra valores acumulados para os meses de {exercicio.id}.
        Filtrando a natureza 31 ou 33, porém a natureza 33 são somente os
        elementos 01 ou 03.
      </div>
    );
  };

  getDescricaoEducacao = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    return (
      <div style={{ color: '#fff' }}>
        O quadro demonstra valores acumulados para os meses de {exercicio.id}.
        Filtrando a Função 12 - Educação.
      </div>
    );
  };

  getDescricaoSaude = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    return (
      <div style={{ color: '#fff' }}>
        O quadro demonstra valores acumulados para os meses de {exercicio.id}.
        Filtrando a Função 10 - Saúde.
      </div>
    );
  };

  render() {
    const {
      arrecadadoSobreEmpenhado,
      valorArrecadado,
      arrecadadoSobrePrevisto,
      arrecadacaoDespesaMes,
      entidadeSelecionada,
      valorEmpenhado,
      repasseConcedido,
      repasseRecebido,
      receitaValorAtualizado,
      despesaValorAtualizado,
      gastosEducacao,
      gastosPessoal,
      gastosSaude,
      history,
      receitaDashboard,
      paramExibirRepasses,
      paramExibirExecDespCompleta
    } = this.props;
    let { width } = this.state;
    const { exercicio } = StorageService.getItem('userInfo');
    let exercicioId = exercicio ? exercicio.id : null;
    const tipoEntidadeLegislativa =
      entidadeSelecionada && entidadeSelecionada.get('tipo') == 'L';
    const tipoEntidadeExecutiva =
      entidadeSelecionada && entidadeSelecionada.get('tipo') == 'E';
    return (
      <section>
        <PageHeader location={this.props.location} />

        <BotaoImprimir />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <ArrecadacaoDespesa
          width={width}
          arrecadacaoDespesaMes={arrecadacaoDespesaMes}
          arrecadadoSobreEmpenhado={arrecadadoSobreEmpenhado}
          exercicio={exercicioId}
          tipoEntidadeLegislativa={tipoEntidadeLegislativa}
          paramExibirRepasses={paramExibirRepasses}
          paramExibirExecDespCompleta={paramExibirExecDespCompleta}
        />
        {entidadeSelecionada && !tipoEntidadeLegislativa && (
          <ReceitaAno
            arrecadadoSobrePrevisto={arrecadadoSobrePrevisto}
            receitaValorAtualizado={receitaValorAtualizado}
            valorArrecadado={valorArrecadado}
            exercicio={exercicioId}
            receitaDashboard={receitaDashboard}
          />
        )}
        <DespesaAno
          valorEmpenhado={valorEmpenhado}
          despesaValorAtualizado={despesaValorAtualizado}
          exercicio={exercicioId}
        />
        <RepasseAno
          repasseConcedido={repasseConcedido}
          repasseRecebido={repasseRecebido}
          exercicio={exercicioId}
        />
        {entidadeSelecionada && (
          <GastosPorTipo
            width={width * 0.8}
            gastosPorTipoMes={gastosPessoal}
            tipo="Pessoal"
            descricao={this.getDescricaoPessoal()}
            titulo="Total de Despesas com Pessoal e Encargos"
            grupoPublicacaoRelatorioLRF={2}
            subGrupoPublicacaoRelatorioLRF={24}
            history={history}
          />
        )}
        {entidadeSelecionada && tipoEntidadeExecutiva && (
          <GastosPorTipo
            width={width * 0.8}
            gastosPorTipoMes={gastosSaude}
            tipo="Saúde"
            descricao={this.getDescricaoSaude()}
            titulo="Total de Despesas com a Função 10 - Saúde"
            grupoPublicacaoRelatorioLRF={1}
            subGrupoPublicacaoRelatorioLRF={20}
            history={history}
          />
        )}
        {entidadeSelecionada && tipoEntidadeExecutiva && (
          <GastosPorTipo
            width={width * 0.8}
            gastosPorTipoMes={gastosEducacao}
            tipo="Educação"
            descricao={this.getDescricaoEducacao()}
            titulo="Total de Despesas com a Função 12 - Educação"
            grupoPublicacaoRelatorioLRF={1}
            subGrupoPublicacaoRelatorioLRF={16}
            history={history}
          />
        )}
      </section>
    );
  }
}

function calculaArrecadadoSobreEmpenhado(arrecadado, empenhado) {
  if (arrecadado <= 0) return 0;
  return 100 - (empenhado * 100) / arrecadado;
}

function calculaReceitaAtualizada(arrecadado, receita) {
  if (receita <= 0) return 0;
  return (arrecadado * 100) / receita;
}

function mapStateToProps(state) {
  const valorArrecadado = state.dashboard.get('valorArrecadado');
  const valorEmpenhado = state.dashboard.get('valorEmpenhado');
  return {
    arrecadacaoDespesaMes: state.dashboard.get('arrecadacaoDespesaMes'),
    valorEmpenhadoRepasseConcedido: state.dashboard.get(
      'valorEmpenhadoRepasseConcedido'
    ),
    valorEmpenhadoRepasseRecebido: state.dashboard.get(
      'valorEmpenhadoRepasseRecebido'
    ),
    receitaValorAtualizado: state.dashboard.get('receitaValorAtualizado'),
    despesaValorAtualizado: state.dashboard.get('despesaValorAtualizado'),
    gastosPessoal: state.dashboard.get('gastosPessoal'),
    valorArrecadado: valorArrecadado,
    valorEmpenhado: valorEmpenhado,
    repasseConcedido: state.dashboard.get('repasseConcedido'),
    repasseRecebido: state.dashboard.get('repasseRecebido'),
    gastosEducacao: state.dashboard.get('gastosEducacao'),
    receitaDashboard: state.dashboard.get('receitaDashboard'),
    gastosSaude: state.dashboard.get('gastosSaude'),
    entidadeSelecionada: state.entidade.get('entidadeSelecionada'),
    arrecadadoSobreEmpenhado: calculaArrecadadoSobreEmpenhado(
      valorArrecadado,
      valorEmpenhado
    ),
    arrecadadoSobrePrevisto: calculaReceitaAtualizada(
      valorArrecadado,
      state.dashboard.get('receitaValorAtualizado')
    ),
    valorArrecadadoMes:
      valorArrecadado + state.dashboard.get('repasseRecebido'),
    paramExibirRepasses: parametroService.getValorParametro(
      state,
      PARAM_RESUMO_ANUAL_EXIBIR_REPASSES
    ),
    paramExibirExecDespCompleta: parametroService.getValorParametro(
      state,
      PARAM_RESUMO_ANUAL_EXIBIR_EXECUCAO_DESP_COMPLETA
    )
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormDashboard);
