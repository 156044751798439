import * as types from './Types.js';

export function findSubvencoes(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCOES,
    payload: fetch(`/api/subvencoes?${filter}`)
  });
}

export function findSubvencaoDetalhes(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO,
    payload: fetch(`/api/subvencoes/detalhe?${filter}`)
  });
}

export function findSubvencaoValoresLiberados(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO_VALORES_LIBERADOS,
    payload: fetch(`/api/subvencoes/valores-liberados?${filter}`)
  });
}

export function findSubvencaoPrestacaoContas(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO_PRESTACAO_CONTAS,
    payload: fetch(`/api/subvencoes/prestacao-contas?${filter}`)
  });
}

export function findSubvencaoAditivos(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO_ADITIVOS,
    payload: fetch(`/api/subvencoes/aditivos?${filter}`)
  });
}

export function findSubvencaoRemuneracoes(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO_REMUNERACOES,
    payload: fetch(`/api/subvencoes/remuneracoes?${filter}`)
  });
}

export function findSubvencaoAnexos(filter) {
  return ({ fetch }) => ({
    type: types.SUBVENCAO_ANEXOS,
    payload: fetch(`/api/subvencoes/anexos?${filter}`)
  });
}

export function findTipoSubvencao() {
  return ({ fetch }) => ({
    type: types.TIPO_SUBVENCAO,
    payload: fetch('/api/subvencoes/tipoSubvencao')
  });
}

export function findCertidaoAnexos(filter) {
  return ({ fetch }) => ({
    type: types.CERTIDAO_ANEXOS,
    payload: fetch(`/api/subvencoes/certidao-anexos?${filter}`)
  });
}
