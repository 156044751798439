import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  arrecadacaoDespesaMes: [],
  receitaValorAtualizado: 1,
  despesaValorAtualizado: 0,
  gastosPessoal: [],
  gastosEducacao: [],
  gastosSaude: [],
  valorArrecadado: 0,
  valorEmpenhadoRepasseRecebido: 0,
  valorEmpenhado: 0,
  valorEmpenhadoRepasseConcedido: 0,
  receitaDashboard: []
});

function sum(prop, list, initialValue = 0) {
  const reducer = (accumulator, currentValue) => {
    return accumulator + currentValue[prop];
  };
  return list.reduce(reducer, initialValue);
}

function atendimentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.GASTOS_EDUCACAO_START:
    case types.GASTOS_PESSOAL_START:
    case types.GASTOS_SAUDE_START:
    case types.ARRECADACAO_DESPESA_MES_START:
    case types.RECEITA_VALOR_ATUALIZADO_START:
    case types.DESPESA_VALOR_ATUALIZADO_START:
    case types.RECEITA_DASHBOARD_START: {
      return startLoad(state);
    }

    case types.GASTOS_EDUCACAO_ERROR:
    case types.GASTOS_PESSOAL_ERROR:
    case types.GASTOS_SAUDE_ERROR:
    case types.ARRECADACAO_DESPESA_MES_ERROR:
    case types.RECEITA_VALOR_ATUALIZADO_ERROR:
    case types.DESPESA_VALOR_ATUALIZADO_ERROR:
    case types.RECEITA_DASHBOARD_ERROR: {
      return endLoad(state);
    }

    case types.ARRECADACAO_DESPESA_MES_SUCCESS: {
      return endLoad(
        state
          .set('arrecadacaoDespesaMes', fromJS(action.payload))
          .set('valorEmpenhado', sum('valorEmpenhado', action.payload))
          .set('repasseConcedido', sum('repasseConcedido', action.payload))
          .set('repasseRecebido', sum('repasseRecebido', action.payload))
          .set('valorArrecadado', sum('valorArrecadado', action.payload))
      );
    }

    case types.RECEITA_DASHBOARD_SUCCESS: {
      return endLoad(state.set('receitaDashboard', fromJS(action.payload)));
    }

    case types.RECEITA_VALOR_ATUALIZADO_SUCCESS: {
      return endLoad(state.set('receitaValorAtualizado', action.payload || 0));
    }
    case types.DESPESA_VALOR_ATUALIZADO_SUCCESS: {
      return endLoad(state.set('despesaValorAtualizado', action.payload || 0));
    }

    case types.GASTOS_EDUCACAO_SUCCESS: {
      return endLoad(state.set('gastosEducacao', fromJS(action.payload)));
    }
    case types.GASTOS_PESSOAL_SUCCESS: {
      return endLoad(state.set('gastosPessoal', fromJS(action.payload)));
    }
    case types.GASTOS_SAUDE_SUCCESS: {
      return endLoad(state.set('gastosSaude', fromJS(action.payload)));
    }
  }

  return state;
}

export default atendimentoReducer;
